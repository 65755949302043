<template>
    <!-- Para gerar novos templates visite https://skeletonreact.com/ -->
    <div v-if="loading" class="loader-table">
        <content-loader
            :width="800"
            :height="150"
            :speed="2"
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
        >
        <circle cx="10" cy="20" r="8" /> 
        <rect x="25" y="15" rx="5" ry="5" width="101" height="10" /> 
        <circle cx="10" cy="50" r="8" /> 
        <rect x="25" y="45" rx="5" ry="5" width="101" height="10" /> 
        <circle cx="10" cy="80" r="8" /> 
        <rect x="25" y="75" rx="5" ry="5" width="101" height="10" /> 
        <circle cx="10" cy="110" r="8" /> 
        <rect x="25" y="105" rx="5" ry="5" width="101" height="10" /> 
        <rect x="142" y="15" rx="5" ry="5" width="101" height="10" /> 
        <rect x="142" y="45" rx="5" ry="5" width="101" height="10" /> 
        <rect x="142" y="75" rx="5" ry="5" width="101" height="10" /> 
        <rect x="142" y="105" rx="5" ry="5" width="101" height="10" /> 
        <rect x="266" y="15" rx="5" ry="5" width="101" height="10" /> 
        <rect x="266" y="45" rx="5" ry="5" width="101" height="10" /> 
        <rect x="266" y="75" rx="5" ry="5" width="101" height="10" /> 
        <rect x="266" y="105" rx="5" ry="5" width="101" height="10" /> 
        <rect x="383" y="15" rx="5" ry="5" width="101" height="10" /> 
        <rect x="383" y="44" rx="5" ry="5" width="101" height="10" /> 
        <rect x="383" y="75" rx="5" ry="5" width="101" height="10" /> 
        <rect x="383" y="105" rx="5" ry="5" width="101" height="10" />
        </content-loader>
    </div>

</template>

<script>
    
import { ContentLoader } from 'vue-content-loader';

export default {
    name: "Loader",
    components: {
        ContentLoader,
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
    },
}
</script>

<style lang="scss" scoped>

</style>