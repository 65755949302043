<script>
    import TextField from '../components/form/TextField.vue'
    import Callout from '../components/Callout.vue'
    import Modal from '../components/Modal.vue'
    import PlugaModal from '../components/modal/integrations/PlugaModal.vue'
    import SwitchField from '../components/form/SwitchField.vue'
    import ActionRow from '../components/ActionRow.vue'
    import PublishLinkPopover from '../components/PublishLinkPopover.vue'
    import Btn from '../components/form/Btn.vue'
    import TopHeader from '../components/TopHeader.vue'
    import { FormMixin } from '@/mixins/FormMixin'

    export default {
        components: { TopHeader, Btn, PublishLinkPopover, ActionRow, SwitchField, PlugaModal, Modal, Callout, TextField },
        mixins: [FormMixin],
        name: "EditorIntegrations",
        data() {
            return {
                showModal: {
                    pluga: false
                }
            }
        },

        methods: {
            openModal(modal) {
                this.showModal[modal] = true                
            },
            closeModal(modal) {
                this.showModal[modal] = false                
            }
        },
    }
</script>

<template>
    <div v-if="form" class="page page-integrations">
        <TopHeader nav="Editor" :data="form">
            <Btn @click.native="updateForm(form)" size="sm" :loading="working.updateform">Publicar</Btn>
        </TopHeader>

        <main class="page-content">
            <div class="container container-sm main-content mt64">
                <div class="page-header mb32">
                    <h1 class="page-title title-xl mb8">Integrações</h1>
                    <p class="page-description title-sm light">Integre seu formulário com milhares de outros serviços online.</p>
                </div> 

                <!-- Integrações -->
                <div class="integrations-list mt32">

                    <!-- Webhooks -->
                    <ActionRow title="Webhooks" description="Notifique uma URL com as novas respostas" class="mb16" :showBody="form.notifications.webhook.active">                    
                        <SwitchField v-model="form.notifications.webhook.active" :active="form.notifications.webhook.active" />                    

                        <template class="" #body>
                            <TextField type="url" v-model="form.notifications.webhook.urls[0]" :data={} :value="form.notifications.webhook.urls[0]" placeholder="https://exemplo.com/webhook" slug="webhook"/>
                        </template>                        
                        
                    </ActionRow>

                    <!-- Pluga -->
                    <ActionRow title="Pluga" description="Integre facilmente seu formulário com centenas de serviços" class="mb16">
                        <Btn v-if="form.apps.pluga.active" @click.native="openModal('pluga')" type="secondary" size="xs" class="mr16">Configurar</Btn>
                        <SwitchField v-model="form.apps.pluga.active" :active="form.apps.pluga.active" />
                    </ActionRow> 

                    <!-- Facebook -->
                    <ActionRow pro title="Facebook" description="Adicione o ID do seu pixel no Facebook" class="mb16" :showBody="form.apps.fbPixel.active">
                        <SwitchField v-model="form.apps.fbPixel.active" :active="form.apps.fbPixel.active" />                        
                        <template class="" #body>
                            <TextField type="url" v-model="form.apps.fbPixel.value" :data={} :value="form.apps.fbPixel.value" placeholder="EX: 0000000000000000" slug="fbpixel"/>
                        </template>
                    </ActionRow>

                    <!-- Google Analytics -->
                    <ActionRow pro title="Google Analytics" description="Adicione o ID de acompanhamento da propriedade no Google Analytics" class="mb16" :showBody="form.apps.gAnalytics.active">
                        <SwitchField v-model="form.apps.gAnalytics.active" :active="form.apps.gAnalytics.active" />                        
                        <template class="" #body>
                            <TextField type="url" v-model="form.apps.gAnalytics.value" :data={} :value="form.apps.gAnalytics.value" placeholder="EX: UA-0000000-00" slug="ga"/>
                        </template>
                    </ActionRow>

                </div>

            </div>
        </main>

        <!-- Pluga Modal -->
        <Modal v-if="form" title="Integração com Pluga" :active="showModal.pluga" @modal-close="closeModal('pluga')">
            <PlugaModal :form="form" :active="showModal.pluga" @modal-close="closeModal('pluga')" @update="updateForm(form)" />
        </Modal>



    </div>
</template>

<style lang="sass" scoped>

</style>