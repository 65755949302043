<script>
    import { ValidateMixin } from '@/mixins/ValidateMixin'
    import LoadingIcon from '../../LoadingIcon.vue'
    import Loader from '../../Loader.vue'
    import Callout from '../../Callout.vue'
    import Btn from '../../form/Btn.vue'
    import TextField from '../../form/TextField.vue'
    import Modal from '../../Modal.vue'
    export default {
        components: { Modal, TextField, Btn, Callout, Loader, LoadingIcon},
        mixins: [ValidateMixin],
        name: "PlugaModal",
        props: {
            form: Object,
            active: Boolean
        },        

        data() {
            return {            
                step: 1,
                code: null,
                schema: null,
                wait: null,
                errors: {
                  link: null
                }
            }
        },


        watch: {
          active(value) {
            if(value) this.getFormSchema()  
          }
        },

        methods: {
          newTest(){
            this.schema = null
            this.code = null
            this.getFormSchema()
          },

            getFormSchema(){
              this.$axios.get(this.$api + 'form/schema/' + this.form.slug)
                          .then(response =>{
                              if(response.status > 299) return
                              this.code = this.getCode(response.data.data)
                              this.schema = response.data.data                            
                          })

            },
            getCode(schema){
                return JSON.stringify(schema, null, 3)
            },

            addPlugaUrl(value){
                if(this.isUrl(value) && value.startsWith('https://api.pluga.co/')){
                  this.errors.link = null
                  this.step++
                  this.$emit("update")
                }

                else{
                  this.errors.link = "O link deve começar com https://api.pluga.co/..."
                }
            },

            copyCode(){
                this.$copyText(this.code)
                this.step++
            },

            back(){
                if(this.step > 1) this.step = this.step -1
                else this.action = null 
            },

            next(){
                this.step++
            },

            closeModal(){
                this.step = 1
                this.$emit('modal-close')
            }

        },

    }
</script>

<template>
    <div class="modal-content">

      <div v-if="step == 1" class="step">
        <h2>1. Acesse sua conta Pluga e crie um webhook</h2>
        <div class="description mt8 mb16">Escolha uma automação do tipo Webhook</div>
        <img src="@/assets/img/apps-pluga.gif" class="mb32" alt="Ilustração do Pluga">
        <Btn href="https://manage.pluga.co/onboarding/apps" target="_blank" size="sm" type="secondary" class="mr8">Acessar a Pluga</Btn>
        <Btn @click.native="next()" size="sm">Pronto, continuar →</Btn>
      </div>

      <div v-if="step == 2" class="step">
        <h2>2. Copie a url de webhook gerada na Pluga</h2>
        <div class="description mt8 mb16">Então, cole o link no campo abaixo:</div>
        <TextField v-model="form.apps.pluga.value" :data="{}" :error="errors.link" class="mb16" placeholder="https://api.pluga.co/..." />
        <Btn @click.native="back()" size="sm" type="secondary" class="mr8">Voltar</Btn>
        <Btn @click.native="addPlugaUrl(form.apps.pluga.value)" size="sm">Continuar</Btn>
      </div>


      <!-- step: 2 -->
      <div v-if="step == 3" class="step-2">
        
        <!-- Step 2: with schema -->
        <div v-if="schema" class="has-code">
          <h2>3. Verifique as perguntas disponíveis</h2>
          <div class="description mt8 mb32">Sentindo falta de algum campo? Faça um <router-link :to="{name: 'FormView', slug: form.slug}" class="link" target="_blank" >novo envio de testes</router-link> preenchendo todos os campos. Depois, clique em atualizar.</div>
          
          <ul class="questions-list mb32">
            <li class="question-item header mb16">
              <div class="question mr8">Pergunta</div>
              <div class="answer">Exemplo de resposta</div>
            </li>
            <li v-for="(item) in schema.respondent.raw_answers" :key="item.question.question_id"  class="question-item mb16">
              <div class="question mr8">{{item.question.question_title}}</div>
              <div class="answer">{{schema.respondent.answers[item.question.question_title]}}</div>
            </li>
          </ul>          

          <Btn @click.native="back()" size="sm" type="secondary" class="mr8">Voltar</Btn>
          <Btn @click.native="newTest()" size="sm" type="secondary" class="mr8">Atualizar campos</Btn>
          <Btn @click.native="copyCode()" size="sm">Continuar</Btn>

        </div>

        <!-- Step 2: No schema -->
        <div v-else class="no-code">
          <h2>3. Faça um envio de testes do seu formulário</h2>
          <div class="description mt8 mb16">Responda todas as perguntas que você deseja integrar. Você poderá excluir depois.</div>
          
          <div class="waiting mb32">
            <LoadingIcon />
            <div class="label mt16 mb16">Aguardando respostas...</div>
            <Btn @click.native="newTest()" size="sm" type="secondary" class="mr8">Atualizar campos</Btn>
          </div>

          <Btn :link="{name: 'FormView', slug: form.slug}" target="_blank" size="sm">Fazer envio de teste</Btn>
        </div>

      </div>

      <div v-if="step == 4" class="step-2">
        <h2>4. Copie o código de integração e cole na Pluga</h2>
        <div class="description mt4">Esse código deve ser adicionado no campo "Modelo de envio dos dados"</div>
        <pre class="code mt16 mb16">
          {{ code }}
        </pre>

        <Btn @click.native="back()" size="sm" type="secondary" class="mr8">Voltar</Btn>
        <Btn @click.native="copyCode()" size="sm">Copiar e continuar</Btn>

      </div>

      <div v-if="step == 5" class="step-3">
        <h2>5. Pronto! No Respondi já está tudo configurado 😎</h2>
        <div class="description mt8 mb16">Após finalizar a configuração na Pluga, você pode fazer um novo envio de testes para garantir que funcionou como esperado.</div>
        <div class="row">
          <Btn @click.native="back()" size="sm" type="secondary" class="mr8">Voltar</Btn>
          <Btn @click.native="closeModal()" :link="{name: 'FormView', slug: form.slug }" target="_blank" size="sm">Concluir e testar</Btn>
        </div>
      </div>

      <Callout class="mt32" emoji="👉">
          Em dúvidas sobre como configurar o Pluga? <a href="https://respondi.helpscoutdocs.com/article/72-pluga-integracao-com-o-google-planilhas" target="_blank" class="link">Leia aqui</a>.
      </Callout>

    
    </div>

</template>



<style lang="sass" scoped>
.row
  display: flex
  align-items: center

.code
  height: 200px
  overflow-x: hidden
  background: $cG9
  color: $cG2
  border-radius: 4px

.description
  line-height: 120%

.no-code
  .waiting
    display: flex
    align-items: center
    flex-direction: column
    padding: $mgLg
    background: $cG1


    .label
      font-size: $fzSm
      color: $cG5

.questions-list
  padding: $mgSm
  background: $cG1
  border-radius: 4px
  font-size: $fzXs
  max-height: 300px
  overflow:  auto

  .question-item
    display: flex
    line-height: 120%

    &.header
      border-bottom: 1px solid $cG3
      padding-bottom: $mgXs

    .question
      flex: 1
      font-weight: bold

    .answer
      flex: 1
      

</style>