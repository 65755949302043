<template>
    <div class="callout-comp" :data-type="type">
        <div v-if="emoji" class="emoji mr8">{{emoji}}</div>
        <div class="message"><slot></slot></div>
    </div>
</template>

<script>
    export default {
        name: "Callout",
        props: {
            emoji: String,
            type: String,
        },
    }
</script>

<style lang="sass" scoped>
.callout-comp
    display: flex
    padding: $mgSm
    background: rgba($cG5, .1)
    color: rgba($cG5, 1)
    font-size: $fzXs
    line-height: 140%
    border-radius: 4px

    .emoji
        margin-top: -2px

    &[data-type="warning"]
        background: lighten($cWarning, 45%)
        color: darken($cWarning, 10%)

    &[data-type="danger"]
        background: lighten($cDanger, 40%)
        color: darken($cDanger, 10%)


</style>